<style lang="scss">
#debug {
  display: none;
}
#frontend {
}
</style>

<template>
  <!--
  <HeaderIntroBoxBrands :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild-v2.jpg')})` }"  headerImageText="WILLHABEN">
  -->
  <SocialMediaIconBox />
  <BrandsBoxMarketplaces
    :brandImgLogo="require('@/assets/img/marken/brands/mojedelo.svg')"
    brandAltText="Mojedelo.com"
    brandHeadline="Mojedelo.com"
    :brandLongText="$t('mojedelo.txt')"
    brandGruendung="2004"
    brandWebsite="https://www.mojedelo.com/"
    brandWebsiteText="www.mojedelo.com"

  />
  <MarkenBoxen />
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderIntroBoxBrands from '../../../components/Frontend/Brands/HeaderIntroBoxBrands.vue';
import BrandsBoxMarketplaces from '../../../components/Frontend/Brands/BrandsBoxMarketplaces.vue';
import MarkenBoxen from '../../../components/Frontend/MarkenBoxen.vue';
import Footer from '../../../components/Frontend/Footer.vue';

export default defineComponent({
  components: {
    SocialMediaIconBox,
    //HeaderIntroBoxBrands,
    MarkenBoxen,
    BrandsBoxMarketplaces,
    Footer,
  },
  setup() {


    return {
     
    };
  },
});
</script>
